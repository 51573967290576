import React from "react";
import { useStreamContext } from "react-activity-feed";
import { useStytch, useStytchSession, useStytchUser } from "@stytch/react";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import { ProfileHeader } from "../components/Profile/ProfileHeader";
import { ProfileDetails } from "../components/Profile/ProfileDetails";
import stars from "../assets/Stars.svg";

import { USER } from "../helpers/fakeData";

const StytchProfile = () => {
  const stytch = useStytch();
  // Get the Stytch User object if available
  const { user } = useStytchUser();
  // Get the Stytch Session object if available
  const { session } = useStytchSession();

  return (
    <div className="card">
      <h1>Profile</h1>
      <h2>User object</h2>
      <pre className="code-block">
        <code>{JSON.stringify(user, null, 2)}</code>
      </pre>

      <h2>Session object</h2>
      <pre className="code-block">
        <code>{JSON.stringify(session, null, 2)}</code>
      </pre>
      <p>
        You are logged in, and a Session has been created. The SDK stores the
        Session as a token and a JWT in the browser cookies as{" "}
        <span className="code">stytch_session</span> and{" "}
        <span className="code">stytch_session_jwt</span> respectively.
      </p>
      {/* Revoking the session results in the session being revoked and cleared from browser storage. The user will return to Login.js */}
      <button className="primary" onClick={() => stytch.session.revoke()}>
        Log out
      </button>
    </div>
  );
};

function ProfilePageInner() {
  const { userData } = useStreamContext();

  return (
    <div style={{ maxWidth: "1024px", width: "100%" }}>
      <ProfileHeader name={userData?.name} />

      {/* <ProfileDetails
        specialty={fakeUser.specialty}
        yearsOfService={fakeUser.yearsOfService}
        employmentType={fakeUser.employmentType}
      /> */}
      {/* 
      <div
        style={{
          backgroundImage: `url("${stars}")`,
          height: "200px",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      /> */}

      <StytchProfile />
    </div>
  );
}

export default function ProfilePage(props) {
  return (
    <AuthenticatedLayout props={props}>
      <ProfilePageInner props={props} />
    </AuthenticatedLayout>
  );
}
