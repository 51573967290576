import { useStytch, useStytchUser } from "@stytch/react";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { AuthenticateRubieUser } from "../../services/RubieAuth";

/*
During both the Magic link and OAuth flow, Stytch will redirect the user back to your application to a specified redirect URL (see Login.js). 
Stytch will append query parameters to the redirect URL which are then used to complete the authentication flow. 
A redirect URL for this example app will look something like: http://localhost:3000/?stytch_token_type=magic_links&token=abc123

TokenAuthenticator will detect the presence of a token in the query parameters, and attempt to authenticate it.
On successful authentication, a session will be created and the user will be shown Profile.js 
*/

const TokenAuthenticator = ({ children }) => {
  const stytch = useStytch();
  const { user } = useStytchUser();

  const [loading, setLoading] = useState(true);

  const [loginError, setLoginError] = useState(null);

  const AuthenticateRubieServer = async () => {
    // Make backend call to authenticate the user with Rubie server
    await AuthenticateRubieUser()
      .then(() => {
        window.location.href = "/";
      })
      .catch((err) => {
        setLoginError(err);
      });
  };

  useEffect(() => {
    // If the stytch SDK is available, and there is no existing user check for a token value in query params

    if (stytch && !user) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const tokenType = queryParams.get("stytch_token_type");

      // If a token is found, authenticate it with the appropriate method
      if (token && tokenType) {
        setLoading(true);

        if (tokenType === "magic_links") {
          stytch.magicLinks
            .authenticate(token, {
              session_duration_minutes: 263520,
            })
            .then(async (r) => {
              await AuthenticateRubieServer();
            })
            .catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (tokenType === "oauth") {
          stytch.oauth
            .authenticate(token, {
              session_duration_minutes: 263520,
            })
            .then(async () => {
              await AuthenticateRubieServer();
            })
            .catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);

      // In the future, we may want to handle the case where the user is already logged in. For now, we'll leave this route as is to handle cases where we want the user to see the authentication screen without getting redirected.
    }
  }, [stytch, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (loginError) {
    return <div>Error: {JSON.stringify(loginError)}</div>;
  }

  return children;
};

export default TokenAuthenticator;
