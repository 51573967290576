import React, { useEffect, useRef } from "react";
import moment from "moment";

import {
  humanizeTimestamp,
  textRenderer,
} from "react-activity-feed/dist/utils";

import { PostHeader } from "./PostHeader";
import "./Post.css";
import { PostFooter } from "./PostFooter";
import Comments from "./PostCommentList";

export default function Post({
  activity,

  setHeightToMainComponent,
  hideCommentButton,
  showComments,
}) {
  let container = useRef(null);

  useEffect(() => {
    if (container) {
      setHeightToMainComponent &&
        setHeightToMainComponent(container?.current?.offsetHeight);
    }
  }, [container, setHeightToMainComponent]);

  if (!activity) return null;

  const { actor, title, text, time } = activity;

  return (
    <div className="PostContainer" ref={container}>
      <PostHeader
        author={actor?.data?.name}
        // status={"Registered Nurse"}
        // registeredTime={"12 years"}
      />
      <div className={`PostBody PostBorderWindow bg-white`}>
        <h1 className="PostTitle">{title}</h1>
        <h3 className="PostContent">{textRenderer(text)}</h3>
        <span className="PostDate">
          {humanizeTimestamp(time, (time) => moment(time))}
        </span>
      </div>
      <PostFooter
        activity={activity}
        postId={activity.id}
        hideCommentButton={hideCommentButton}
      />
      {showComments && <Comments activity={activity} />}
    </div>
  );
}
