import React from "react";
import { Progress } from "@nextui-org/react";

export default function ProgressLoader() {
  return (
    <Progress
      size="sm"
      radius="sm"
      isIndeterminate
      classNames={{
        base: "max-w-md mt-2 mb-4",
        track: "bg-transparent",
        indicator: "bg-rubie-green",
      }}
      aria-label="Loading..."
    />
  );
}
