import React from "react";
import { StreamApp } from "react-activity-feed";
import { Analytics } from "@vercel/analytics/react";

import { NavFooter, PageFooterHeight } from "../components/NavFooter/NavFooter";
import {
  PageHeader,
  pageHeaderHeight,
} from "../components/PageHeader/PageHeader";

import "react-activity-feed/dist/index.css";

import { STREAM_PUBLIC_API_KEY, STREAM_PUBLIC_APP_ID } from "../helpers/config";
import { GetUserStreamToken } from "../services/RubieAuth";

function getPageHeight({
  hideHeader,
  hideFooter,
  customFooterHeight,
  customHeaderHeight,
}) {
  let offset = 0;

  if (!hideFooter) {
    // page footer height
    offset += PageFooterHeight;
  }

  if (!hideHeader) {
    // page header height
    offset += pageHeaderHeight;
  }

  if (customFooterHeight) {
    offset += customFooterHeight;
  }

  if (customHeaderHeight) {
    offset += customHeaderHeight;
  }

  return window.innerHeight - offset;
}

function AuthenticatedLayoutInner({
  children,
  backgroundColor,

  hideFooter,
  hideHeader,

  customFooterHeight,
  customHeaderHeight,

  customHeader,
  customFooter,

  streamToken,
}) {
  let [height, setHeight] = React.useState(
    getPageHeight({
      hideFooter,
      hideHeader,
      customFooterHeight,
      customHeaderHeight,
    })
  );

  React.useEffect(() => {
    const handleResize = () => {
      setHeight(
        getPageHeight({
          hideFooter,
          hideHeader,
          customFooterHeight,
          customHeaderHeight,
        })
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // render streamapp wrapper iff streamToken is available
  const body = (
    <div
      style={{
        backgroundColor: backgroundColor ?? "#FAF7E8",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {!hideHeader && <PageHeader />}
      {customHeader}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: 20,
          flexFlow: 1,
          overflowY: "auto",
          maxHeight: height,
          minHeight: height,
          alignItems: "center",
        }}
      >
        {children}
      </div>
      {!hideFooter && <NavFooter />}
      {customFooter}
    </div>
  );

  const withAnalytics = (body) => (
    <>
      {body}
      <Analytics />
    </>
  );

  if (!streamToken || streamToken === "") {
    return withAnalytics(body);
  } else {
    return withAnalytics(
      <StreamApp
        apiKey={STREAM_PUBLIC_API_KEY}
        appId={STREAM_PUBLIC_APP_ID}
        token={streamToken}
      >
        {body}
      </StreamApp>
    );
  }
}

export function AuthenticatedLayout(props) {
  const [streamToken, setStreamToken] = React.useState("");

  React.useEffect(() => {
    GetUserStreamToken().then((token) => {
      setStreamToken(token);
    });
  }, []);

  return <AuthenticatedLayoutInner {...props} streamToken={streamToken} />;
}
