import React from "react";
import { Avatar } from "@nextui-org/avatar";
import "./PostHeader.css";
import avatarImage from "../../assets/avatarImage.svg";
import { getInitialLetters } from "../../helpers/user";

export function PostHeader({ author, status, registeredTime }) {
  return (
    <div className="PostHeaderContainer bg-white">
      <div className="PostHeaderAvatar PostHeaderBorderLeftWindow">
        <Avatar src={avatarImage} style={{ backgroundColor: "transparent" }} />
        <h3
          className="uppercase"
          style={{ position: "absolute" }}
        >{`${getInitialLetters(author)}`}</h3>
      </div>
      <div style={{ width: "100%", backgroundColor: "#FAF7E8" }}>
        <div className={"PostHeaderNameContainer PostHeaderBorderRightWindow"}>
          <h1 className="PostHeaderTitle">{author}</h1>
        </div>
        <div className="PostHeaderDescriptionContainer PostHeaderBorderRightWindow">
          <span className="PostHeaderDescription">
            {status} | {registeredTime}
          </span>
        </div>
      </div>
    </div>
  );
}
