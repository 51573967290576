import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { repeat, capitalize } from "lodash";
import { CaretCircleLeft } from "@phosphor-icons/react";

import "./ThreadHeader.css";

export const ThreadHeaderHeight = 74;

export function ThreadHeader({ feedId = "" }) {
  const navigate = useNavigate();

  const [titleBackground, setTitleBackground] = useState();

  // Can map feedId to a user-facing title in the future

  useEffect(() => {
    setTitleBackground(repeat(" • " + capitalize(feedId), 50));
  }, [feedId]);

  return (
    <div
      className="ThreadHeaderContainer"
      style={{
        height: `${ThreadHeaderHeight}px`,
      }}
    >
      <div className="ThreadHeaderTitleContainer">
        <Button color="transparent" onClick={() => navigate(-1)}>
          <div className="ThreadHeaderDetailsContainer">
            <CaretCircleLeft size={19} color="#263322" />
            <span className="ThreadHeaderTitle uppercase">{feedId}</span>
          </div>
        </Button>
      </div>
      <div className="ThreadHeaderBackgroundTitleContainer">
        <div className="ThreadHeaderBackgroundTitle">
          <span style={{ color: "#cecece" }}>{titleBackground}</span>
        </div>
      </div>
    </div>
  );
}
