import React from "react";
import { ReactComponent as Logo } from "../../assets/rubieLogo.svg";

import "./PageHeader.css";
import { Link } from "react-router-dom";

export const pageHeaderHeight = 58;

export function PageHeader() {
  return (
    <div className="PageHeader" style={{ height: `${pageHeaderHeight}px` }}>
      <Link to="/">
        <Logo />
      </Link>
    </div>
  );
}
