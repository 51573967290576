import React from "react";
import { Button } from "@nextui-org/button";

import "./RubieButton.css";

export function RubieButton({ onPress, size, color, style, children }) {
  return (
    <Button
      onPress={onPress}
      style={style}
      color={color}
      size={size}
      className="RubieButton"
    >
      {children}
    </Button>
  );
}
