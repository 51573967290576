import React from "react";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import CreatePost from "../components/CreatePost";

export default function CreatePostPage() {
  return (
    <AuthenticatedLayout hideFooter hideHeader>
      <CreatePost />
    </AuthenticatedLayout>
  );
}
