import RubieLocalStorage, { LOCAL_STORAGE_KEYS } from "./RubieLocalStorage";

// eslint-disable-next-line import/no-cycle
import RubieApolloClient from "./RubieApolloClient";
import { AUTHENTICATE_RUBIE_USER_MUTATION } from "../graphql/auth";
import * as Sentry from "@sentry/react";

export async function AuthenticateRubieUser() {
  return RubieApolloClient.mutate({
    mutation: AUTHENTICATE_RUBIE_USER_MUTATION,
    variables: {},
  })
    .then((r) => {
      const userId = r?.data?.authenticateRubieUser?.userId;
      const streamToken = r?.data?.authenticateRubieUser?.streamToken;

      // Set localstorage data
      if (userId) {
        // Chain the localstorage promise
        return RubieLocalStorage.save({
          key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
          data: {
            userId,
            streamToken,
          },
        });
      }

      console.log("Login response userid is null");

      Sentry.captureException(new Error("Login response userid is null"));
      throw new Error("Login response userid is null");
    })
    .catch((e) => {
      Sentry.captureException(e);

      console.log("Login Error:", e);
      throw e;
    });
}

export const Logout = async () => {
  // Clear local stores
  return RubieLocalStorage.remove({
    key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
  })
    .then(() => {
      RubieApolloClient.resetStore();
      window.location.href = "/";
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log("Error in RubieAuth.Logout", err);
      throw err;
    });
};

export async function GetLocalSessionData() {
  let localAuthData = null;

  await RubieLocalStorage.load({
    key: LOCAL_STORAGE_KEYS.CURRENT_USER_KEY,
  })
    .then((ret) => {
      if (!ret) {
        return null;
      }

      localAuthData = {};
      localAuthData.userId = ret?.userId;
      localAuthData.streamToken = ret?.streamToken;
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log(err.message);
    });

  return localAuthData;
}

export async function GetUserStreamToken() {
  const localSessionData = await GetLocalSessionData();
  return localSessionData?.streamToken;
}
