import { isNil } from "lodash";

export function getInitialLetters(name) {
  const fullNameSplitted = name?.split(' ');
  return getLetters(fullNameSplitted);
}

function getLetters(nameArr) {
  if (isNil(nameArr)) return '';
  if (nameArr.length === 1) {
    return nameArr[0][0];
  }
  return `${nameArr[0][0]}${nameArr[nameArr.length - 1][0]}`;
}