import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import { TitleHeader } from "../components/TitleHeader/TitleHeader";
import { NOTIFICATIONS } from "../helpers/fakeData";

// import Notification from "../components/Notification";

function NotificationsPageInner() {
  return (
    <InfiniteScroll
      dataLength={NOTIFICATIONS.length}
      next={() => console.log("")}
      hasMore={false} // Replace with a condition based on your data source
      loader={<p>Loading...</p>}
    >
      <TitleHeader title="Activity" />

      {/* coming soon */}
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <h3>Coming soon</h3>
      </div>

      {/* {NOTIFICATIONS.map((item) => (
          <Notification key={item.date} data={[item]} />
        ))} */}
    </InfiniteScroll>
  );
}

export default function NotificationsPage(props) {
  return (
    <AuthenticatedLayout props={props}>
      <NotificationsPageInner props={props} />
    </AuthenticatedLayout>
  );
}
