import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { APP_NAME, APP_VERSION, GetActiveAPIUrl } from "../helpers/config";
// eslint-disable-next-line import/no-cycle

const httpLink = createUploadLink({
  uri: GetActiveAPIUrl(),
  credentials: "include", // Include cookies with requests
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      appID: "rubie-web",
    },
    credentials: "include", // Include cookies with requests
  };
});

const RubieApolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  name: APP_NAME,
  version: APP_VERSION,
  cache: new InMemoryCache(),
  fetchPolicy: "network-only",
  credentials: "include", // Include cookies with requests
});

export default RubieApolloClient;
