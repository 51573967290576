import moment from "moment";

export const USER = {
  name: "Piper Keller",
  specialty: "Neonatal",
  yearsOfService: 12,
  employmentType: "Travel",
};

const COMMENTS = [
  {
    id: 1,
    author: "Danielle Reebling",
    message: "I would check out this nursing org for some help. They’re great.",
    createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
    likes: 120,
    replies: [
      {
        id: 2,
        author: "Testing User",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 10,
      },
      {
        id: 3,
        author: "Sam Scott",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 1,
      },
      {
        id: 4,
        author: "Farid Testing",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 0,
      },
    ],
  },
  {
    id: 5,
    author: "Rubens Junior",
    message: "I would check out this nursing org for some help. They’re great.",
    createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
    likes: 10,
    replies: [
      {
        id: 6,
        author: "Testing User",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 10,
      },
      {
        id: 7,
        author: "Sam Scott",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 1,
      },
      {
        id: 8,
        author: "Farid Testing",
        message:
          "I would check out this nursing org for some help. They’re great.",
        createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
        likes: 0,
      },
    ],
  },
  {
    id: 9,
    author: "Rubens Junior",
    message: "I would check out this nursing org for some help. They’re great.",
    createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
    likes: 1010,
    replies: [],
  },
  {
    id: 10,
    author: "Rubens Junior",
    message: "I would check out this nursing org for some help. They’re great.",
    createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
    likes: 1010,
    replies: [],
  },
  {
    id: 11,
    author: "Rubens Junior",
    message: "I would check out this nursing org for some help. They’re great.",
    createTimeUtc: new moment(new Date()).add(-1, "h").toDate(),
    likes: 1010,
    replies: [],
  },
];

export const POSTS = [
  {
    author: "Rubens Junior",
    status: "Registered Nurse",
    registeredTime: "12 years",
    title: "My certifications are about to expire..",
    content:
      "Wondering what i can do to help speed up the process so I can do to maybe speed along the process or understand how long you think it might take to do? This is my testing with more content",
    likes: 12,
    comments: 12,
    createTimeUtc: "2024-02-01",
    id: 1,
    replies: COMMENTS,
  },
  {
    author: "Sam Scott",
    status: "Registered Nurse",
    registeredTime: "3 years",
    title: "My certifications are about to expire..",
    content:
      "Wondering what i can do to help speed up the process so I can do to maybe speed along the process or understand how long you think it might take to do?",
    likes: 1,
    comments: 45,
    createTimeUtc: "2024-02-01",
    id: 2,
    replies: COMMENTS,
  },
  {
    author: "Sam Scott",
    status: "Registered Nurse",
    registeredTime: "3 years",
    title: "My certifications are about to expire..",
    content:
      "Wondering what i can do to help speed up the process so I can do to maybe speed along the process or understand how long you think it might take to do?",
    likes: 1,
    comments: 45,
    createTimeUtc: "2024-02-01",
    id: 3,
    replies: COMMENTS,
  },
  {
    author: "Sam Scott",
    status: "Registered Nurse",
    registeredTime: "3 years",
    title: "My certifications are about to expire..",
    content:
      "Wondering what i can do to help speed up the process so I can do to maybe speed along the process or understand how long you think it might take to do?",
    likes: 1,
    comments: 0,
    createTimeUtc: "2024-02-01",
    id: 4,
  },
];

export const NOTIFICATIONS = [
  {
    date: new Date(),
    posts: [
      {
        type: "news",
        massage: "There’s a new stack of Gems for you to check out!",
        date: new Date(),
        visualized: false,
      },
      {
        type: "comment",
        userName: "Summer Fern",
        massage:
          "If you guys are in Fairfield Med today I’d be down for some lunch",
        date: new Date(),
        visualized: false,
      },
    ],
  },
  {
    date: moment().add(-1, "days").toDate(),
    posts: [
      {
        type: "comment",
        userName: "Wilson Smith",
        massage:
          "I don’t think Florence Nightingale is all that bad, I mean after all, she did completely changed how we view this..",
        date: moment().add(-1, "days").toDate(),
        visualized: false,
      },
      {
        type: "like",
        likes: 3,
        userName: "Selina Dejon",
        massage:
          "I don’t think Florence Nightingale is all that bad, I mean after all, she did completely changed how we view this..",
        date: moment().add(-1, "days").toDate(),
        visualized: false,
      },
      {
        type: "news",
        massage: "New gem just posted in the Career mine.",
        date: moment().add(-1, "days").toDate(),
        visualized: true,
      },
      {
        type: "news",
        massage: "Welcome to Rubie! Complete your Profile to start posting.",
        date: moment().add(-1, "days").toDate(),
        visualized: true,
      },
    ],
  },
  {
    date: moment().add(-2, "days").toDate(),
    posts: [
      {
        type: "news",
        massage: "There’s a new stack of Gems for you to check out!",
        date: moment().add(-2, "days").toDate(),
        visualized: false,
      },
    ],
  },
];

export const TRENDINGS = [
  {
    id: 1,
    name: "Jane Irfan",
    date: moment().add(-11, "months").toDate(),
    title: "The long awaited 🎧💀Graveyard Shift Playlist💀🎧",
    message: "Preview of more of the post content...",
    category: "lifestyle",
    job: "Nurse",
    likes: 12,
    comments: 143,
  },
  {
    id: 2,
    name: "Selina Dijon",
    date: moment().add(-16, "months").toDate(),
    title: "The long awaited 🎧💀Graveyard Shift Playlist💀🎧",
    message: "Preview of more of the post content...",
    category: "career",
    job: "Nurse",
    likes: 12,
    comments: 143,
  },
  {
    id: 3,
    name: "Jane Irfan",
    date: moment().add(-5, "years").toDate(),
    title: "The long awaited 🎧💀Graveyard Shift Playlist💀🎧",
    message: "Preview of more of the post content...",
    category: "wellness",
    job: "Nurse",
    likes: 12,
    comments: 143,
  },
];

export const PREVIOUS_SEARCH = ["DESTINATIONS", "CERTIFAC", "MIAMI"];

export const SUGGESTIONS_SEARCH = [
  "HELP WITH CERTIFICATIONS",
  "CERTIFICATIONS",
];
