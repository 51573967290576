import { gql } from "@apollo/client";

export const AUTHENTICATE_RUBIE_USER_MUTATION = gql`
  mutation AuthenticateRubieUser {
    authenticateRubieUser {
      userId
      streamToken
    }
  }
`;
