import React from "react";
import "./StartBrowsing.css";
import { ReactComponent as Actions } from "../../assets/arrowAction.svg";
import career from "../../assets/career.png";
import lifestyle from "../../assets/lifestyle.png";
import wellness from "../../assets/wellness.png";
import { useNavigate } from "react-router-dom";
import {
  FEED_ID_PATH,
  TOPIC_ID_LIFESTYLE,
  TOPIC_ID_CAREER,
  TOPIC_ID_BREAKROOM,
  FEED_TOPIC_TITLES,
} from "../../routes";

export function StartBrowsing() {
  const navigate = useNavigate();

  return (
    <div className="StartBrowsingContainer">
      {/* <div className="StartBrowsingTitle">START BROWSING</div> */}
      <div className="StartBrowsingCards mt-2">
        <div
          onClick={() =>
            navigate(FEED_ID_PATH.replace(":feedId", TOPIC_ID_CAREER))
          }
          className="StartBrowsingCard StartBrowsingCareer"
        >
          <div className="StartBrowsingCardTitle">
            {FEED_TOPIC_TITLES[TOPIC_ID_CAREER]}
          </div>
          <div className="StartBrowsingCardIcon">
            <Actions style={{ fontSize: 12 }} />
          </div>
          <img src={career} alt={FEED_TOPIC_TITLES[TOPIC_ID_CAREER]} />
        </div>

        <div
          onClick={() =>
            navigate(FEED_ID_PATH.replace(":feedId", TOPIC_ID_LIFESTYLE))
          }
          className="StartBrowsingCard StartBrowsingLifestyle"
        >
          <div className="StartBrowsingCardTitle">
            {FEED_TOPIC_TITLES[TOPIC_ID_LIFESTYLE]}
          </div>
          <div className="StartBrowsingCardIcon">
            <Actions style={{ fontSize: 12 }} />
          </div>
          <img src={lifestyle} alt={FEED_TOPIC_TITLES[TOPIC_ID_LIFESTYLE]} />
        </div>

        <div
          onClick={() =>
            navigate(FEED_ID_PATH.replace(":feedId", TOPIC_ID_BREAKROOM))
          }
          className="StartBrowsingCard StartBrowsingWellness"
        >
          <div className="StartBrowsingCardTitle">
            {FEED_TOPIC_TITLES[TOPIC_ID_BREAKROOM]}
          </div>
          <div className="StartBrowsingCardIcon">
            <Actions style={{ fontSize: 12 }} />
          </div>
          <img src={wellness} alt={FEED_TOPIC_TITLES[TOPIC_ID_BREAKROOM]} />
        </div>
      </div>
    </div>
  );
}
