import React from "react";
import { useParams } from "react-router-dom";

import { SinglePost, useStreamContext } from "react-activity-feed";

import "./Thread.css";

import { AuthenticatedLayout } from "../../layoutsHOC/AuthenticatedLayout";

import Post from "../../components/Post";
import {
  ThreadHeader,
  ThreadHeaderHeight,
} from "../../components/Thread/ThreadHeader";
import {
  ThreadFooter,
  ThreadFooterHeight,
} from "../../components/Thread/ThreadFooter";

import ProgressLoader from "../../components/ProgressLoader";
import InfiniteScrollPaginator from "../../components/InfiniteScrollPaginator";
import { FeedGroupAndGroupIdFromFeedId } from "../../routes";

export default function ThreadPage() {
  let { activityId, feedId } = useParams();

  const { user } = useStreamContext();

  // React.useEffect(() => {
  //   console.log("Thread mounted");
  //   return () => {
  //     console.log("Thread unmounted");
  //   };
  // }, []);

  if (!activityId || !feedId) return null;

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId(
    feedId,
    user
  );

  return (
    <AuthenticatedLayout
      hideHeader
      hideFooter
      customHeaderHeight={ThreadHeaderHeight}
      customHeader={<ThreadHeader feedId={feedGroupId} />}
    >
      <SinglePost
        notify
        feedGroup={feedGroup}
        userId={feedGroupId}
        activityId={activityId}
        LoadingIndicator={ProgressLoader}
        Paginator={InfiniteScrollPaginator}
        options={{
          withRecentReactions: true,
          withOwnReactions: true,
          withReactionCounts: true,
          withOwnChildren: true,
        }}
        Activity={({ activity }) => (
          <>
            <Post key={activity?.id} activity={activity} showComments />
            <div style={{ height: `${ThreadFooterHeight}px` }} />
            <ThreadFooter activity={activity} />
          </>
        )}
      />
    </AuthenticatedLayout>
  );
}
