import React, { useMemo, useState } from "react";
import MultSelect from "../../components/SelectAccordion/SelectAccordion";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { Button } from "@nextui-org/button";
import { isEmpty, isNil } from "lodash";

import { useStreamContext } from "react-activity-feed";

import "react-quill/dist/quill.snow.css";
import "./CreatePost.css";

import { Loading } from "../../components/Loading/Loading";
import { CreatePostHeader } from "./CreatePostHeader";

import {
  TIMELINE_PATH,
  FEED_ID_USER,
  FEED_TOPIC_VALUE_LABELS,
  FEED_ID_TOPIC,
} from "../../routes";

const { TextArea } = Input;

export default function CreatePost() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [openTopic, setOpenTopic] = useState(false);
  const [selectedTopicFeedId, setselectedTopicFeedId] = useState();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { client, userData, user } = useStreamContext();

  const userName = userData?.name;

  const isDisabled = useMemo(
    () =>
      isEmpty(selectedTopicFeedId) ||
      isNil(selectedTopicFeedId) ||
      title === "" ||
      description === "",
    [title, description, selectedTopicFeedId]
  );

  const onCreatePostHandleClick = async () => {
    if (isDisabled) {
      return;
    }

    setLoading(true);

    const postUUID = crypto.randomUUID();

    client
      .feed(FEED_ID_USER)
      .addActivity({
        verb: "post",

        actor: user,

        title: title,
        text: description,

        object: `post:${postUUID}`,

        topic: FEED_TOPIC_VALUE_LABELS.find(
          (topic) => topic.value === selectedTopicFeedId
        )?.label,

        foreign_id: `post:${postUUID}`,

        to: [FEED_ID_TOPIC + ":" + selectedTopicFeedId],
      })
      .then(() => {
        setLoading(false);
        navigate(TIMELINE_PATH, { replace: true });
      })
      .catch((error) => {
        console.error("Error creating post", error);
        alert("Error creating post: " + error.message);

        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="CreatePostContainer"
      style={{
        backgroundColor: openTopic ? "#FCF3FE" : "#FCFCFE",
      }}
    >
      <CreatePostHeader
        color={openTopic ? "#EBB0EA" : "#E2DEC6"}
        backgroundTitle={openTopic ? "#FCEAFE" : "#FAF7E8"}
        title={openTopic ? "Select a Topic" : "Craft a Gem"}
        backgroundAction={openTopic ? "#FDE0FE" : "#FAF7E8"}
        onCloseAction={() =>
          openTopic
            ? setOpenTopic(false)
            : navigate(TIMELINE_PATH, { replace: true })
        }
      />
      <MultSelect
        open={openTopic}
        options={FEED_TOPIC_VALUE_LABELS}
        placeholder="Select a Topic"
        value={selectedTopicFeedId}
        setValue={setselectedTopicFeedId}
        setOpen={setOpenTopic}
      />
      {!openTopic && (
        <>
          <div className="px-4 pt-4">
            <div className="CreatePostPostingText">
              Posting as <strong>{userName}</strong>
            </div>
          </div>
          <div className="CreatePostInputContainer px-1.5">
            <Input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              className="CreatePostInputTitle"
              variant="borderless"
              placeholder="What’s on your mind?"
            />

            <TextArea
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              variant="borderless"
              placeholder="Add some details. Everyone can read this, keep personal information to yourself."
              className="CreatePostInputDescription"
            />
          </div>

          <div className="CreatePostButtonContainer">
            <Button
              isDisabled={isDisabled}
              className={`w-full h-full`}
              radius="none"
              color={isDisabled ? undefined : "transparent"}
              onClick={onCreatePostHandleClick}
            >
              <span>POST</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
