import React, { useEffect, useState } from 'react';
import './CreatePostHeader.css'
import { ReactComponent as Close } from '../../assets/Close.svg';
import { repeat } from 'lodash';

export function CreatePostHeader({ title, backgroundAction, backgroundTitle, color, onCloseAction }) {
  const [titleBackground, setTitleBackground] = useState();

  useEffect(() => {
    setTitleBackground(repeat(' ● ' + title, 50));
  }, [title]);

  return (
    <div className='CreatePostHeaderContainer'>
      <div className='CreatePostClose' style={{ backgroundColor: backgroundAction }}>
        <Close onClick={onCloseAction} />
      </div>
      <div className='CreatePostTitleDiv'>
        <div className='CreatePostTitle'>
          <span style={{ backgroundColor: backgroundTitle }}>{title}</span>
        </div>
        <div style={{ backgroundColor: backgroundTitle }} className='CreatePostBackgroundTitle'>
          <span style={{ color: color }}>
            {titleBackground}
          </span>
        </div>
      </div>
    </div>
  )
}