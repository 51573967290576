import React, { useCallback, useMemo } from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import "./SelectAccordion.css";
import { ReactComponent as Check } from "../../assets/Check.svg";
import { ReactComponent as CaretDown } from "../../assets/CaretDown.svg";

export default function SelectAccordion({
  options,
  placeholder,
  value,
  setValue,
  setOpen,
  open,
}) {
  const title = useMemo(() => {
    if (value != null && !open) {
      const label = options.find((x) => x.value === value)?.label;

      return <div className="SelectAccordionTag">{label.toUpperCase()}</div>;
    }
    return <div className="SelectAccordionPlaceholder">{placeholder}</div>;
  }, [placeholder, value, open, options]);

  const handlerChangeValue = useCallback(
    (newValue) => {
      setValue(newValue);
      if (newValue) {
        setOpen(false);
      }
    },
    [setOpen, setValue]
  );

  return (
    <Accordion
      selectedKeys={open ? ["1"] : []}
      onExpandedChange={(e) => {
        setOpen && setOpen((x) => !x);
      }}
      className="SelectAccordion"
    >
      <AccordionItem
        indicator={<CaretDown className="SelectAccordionCaret" />}
        className="SelectAccordionAccordionItem"
        key="1"
        title={title}
      >
        {options.map((option) => (
          <div
            onClick={() =>
              handlerChangeValue(
                value !== option.value ? option.value : undefined
              )
            }
            className={`SelectAccordionItem ${
              value === option.value && "SelectAccordionChecked"
            }`}
          >
            <div className="SelectAccordionCheck">
              {value === option.value ? <Check /> : <>&nbsp;</>}
            </div>
            {option.label}
          </div>
        ))}
      </AccordionItem>
    </Accordion>
  );
}
