import * as React from "react";

import { Routes, Route } from "react-router-dom";

// Public pages
import LoginPage from "./pages/Login";
import AuthenticatePage from "./pages/Authenticate";

// Private pages
import TimelinePage from "./pages/Timeline";
import NotificationsPage from "./pages/Notifications";
import SearchPage from "./pages/Search";
import ProfilePage from "./pages/Profile";
import ThreadPage from "./pages/Thread";
import CreatePostPage from "./pages/CreatePost";

// HOCs
import AuthenticatedRoute from "./layoutsHOC/AuthenticatedRoute";

// Public routes
export const LOGIN_PATH = "/login";
export const AUTH_PATH = "/authenticate";

// Private routes
export const TIMELINE_PATH = "/";
export const FEED_ID_PATH = "/t/:feedId/";
export const THREAD_PATH = "/t/:feedId/:activityId";
export const CREATE_POST_PATH = "/create";

export const NOTIFICATIONS_PATH = "/notifications";
export const SEARCH_PATH = "/search";
export const PROFILE_PATH = "/profile";

// Stream feed ids
export const FEED_ID_USER = "user";

export const FEED_ID_TIMELINE = "timeline";

export const FEED_ID_TOPIC = "topic";

export const TOPIC_ID_LIFESTYLE = "lifestyle";
export const TOPIC_ID_CAREER = "career";
export const TOPIC_ID_BREAKROOM = "breakroom";

export const FEED_TOPIC_TITLES = {
  [TOPIC_ID_LIFESTYLE]: "Lifestyle",
  [TOPIC_ID_CAREER]: "Career",
  [TOPIC_ID_BREAKROOM]: "Breakroom",
};

export const FEED_TOPIC_VALUE_LABELS = Object.entries(FEED_TOPIC_TITLES).map(
  ([value, label]) => ({ value, label })
);

export const IsTopicTypeFeedId = (feedId) =>
  Object.keys(FEED_TOPIC_TITLES).includes(feedId);

export const FeedGroupAndGroupIdFromFeedId = (feedId, user) => {
  if (!feedId || feedId === "") {
    feedId = FEED_ID_TIMELINE;
  }

  let feedGroup,
    feedGroupId = null;

  if (IsTopicTypeFeedId(feedId)) {
    feedGroup = FEED_ID_TOPIC;
    feedGroupId = feedId;
  } else if (feedId === FEED_ID_TIMELINE) {
    feedGroup = FEED_ID_TIMELINE;
    feedGroupId = user?.id;
  } else {
    feedGroup = feedId;
  }

  return { feedGroup, feedGroupId };
};

// export const FEED_ID_TIMELINE = "timeline";
// export const FEED_ID_NOTIFICATIONS = "notifications";
// notification feed tbd

export function RubieRoutes() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path={LOGIN_PATH} element={<LoginPage />} />

      <Route path={AUTH_PATH} element={<AuthenticatePage />} />

      {/* Private routes */}
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path={TIMELINE_PATH} element={<TimelinePage />} />
        <Route path={FEED_ID_PATH} element={<TimelinePage />} />
        <Route path={THREAD_PATH} element={<ThreadPage />} />

        <Route path={CREATE_POST_PATH} element={<CreatePostPage />} />

        <Route path={PROFILE_PATH} element={<ProfilePage />} />

        <Route path={NOTIFICATIONS_PATH} element={<NotificationsPage />} />

        <Route path={SEARCH_PATH} element={<SearchPage />} />
      </Route>
    </Routes>
  );
}
