import React from "react";
import "./ProfileHeader.css";
import { Avatar } from "../Avatar/Avatar";

export function ProfileHeader({ name }) {
  return (
    <div className="ProfileHeaderContainer">
      <Avatar backgroundColor="#FFC3FE" name={name} />
      <div className="ProfileHeaderName">{name}</div>
    </div>
  );
}
