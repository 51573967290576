import { useNavigate } from "react-router-dom";

import handShake from "../assets/handShake.png";
import { RubieButton } from "../components/RubieButton/RubieButton";
import { AUTH_PATH } from "../routes";
import PublicLayout from "../layoutsHOC/PublicLayout";

export default function LoginPage() {
  const navigate = useNavigate();

  const title = (
    <>
      <img width={40} src={handShake} alt="Shake Hands" /> Welcome to Rubie
    </>
  );

  const subtitle = "Join a thriving network of nurses just like you.";

  return (
    <PublicLayout title={title} subtitle={subtitle}>
      {/* <RubieButton
          onPress={() => navigate(TIMELINE_PATH)}
          style={{ width: 330 }}
          size="sm"
        >
          Create an Account
        </RubieButton> */}
      <RubieButton
        onPress={() => navigate(AUTH_PATH)}
        color="primary"
        style={{ width: 330 }}
        size="sm"
      >
        Log in
      </RubieButton>
    </PublicLayout>
  );
}
