import React from "react";

import Lottie from "lottie-react";
import groovyWalkAnimation from '../../assets/lottie/loadingAnimation.json';
import './Loading.css'

export const Loading = () => {
  return (<div className="LoadingContainer w-full h-full flex">
    <Lottie animationData={groovyWalkAnimation} />
  </div>)
};
