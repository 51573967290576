import React from "react";

import "./PostFooter.css";
import LikeButton from "../LikeButton";
import CommentsButton from "./CommentsButton";
import PostActionsButton from "./PostActionsButton";

export function PostFooter({ activity, hideCommentButton, showComments }) {
  return (
    <div className="PostFooterContainer bg-white">
      <div style={{ display: "flex" }}>
        <LikeButton activity={activity} className="max-width-1024-border-l-0" />
        <CommentsButton
          activity={activity}
          hideCommentButton={hideCommentButton}
          showComments={showComments}
        />
      </div>
      <PostActionsButton activity={activity} />
    </div>
  );
}
