import "./PublicLayout.css";
import { PRIVACY_URL, TERMS_URL } from "../helpers/config";

export default function PublicLayout({ title, subtitle, children }) {
  return (
    <div className="WelcomeContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <div className="WelcomeTitle">{title}</div>
        <div className="WelcomeDescriptionText">{subtitle}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          gap: 20,
        }}
      >
        {children}

        <div className="flex-1" />
        <div className="WelcomeFooter">
          <a
            target="_blank"
            rel="noreferrer"
            className="WelcomeFooterText"
            href={PRIVACY_URL}
          >
            Privacy Policy
          </a>
          <div style={{ width: 25 }} />
          <a
            target="_blank"
            rel="noreferrer"
            className="WelcomeFooterText"
            href={TERMS_URL}
          >
            Terms of Service
          </a>
        </div>
        <div className="WelcomeFooter">
          <p className="CopyRight">
            &copy; Veritas Labs, Inc {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
}
