import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar, NavbarContent, NavbarItem, Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

import { Plus } from "@phosphor-icons/react";

import { ReactComponent as Home } from "../../assets/Home.svg";
import { ReactComponent as HomeSelected } from "../../assets/HomeSelected.svg";

import { ReactComponent as Activity } from "../../assets/Activity.svg";
import { ReactComponent as ActivitySelected } from "../../assets/ActivitySelected.svg";

import { ReactComponent as Search } from "../../assets/Search.svg";
import { ReactComponent as SearchSelected } from "../../assets/SearchSelected.svg";

import { ReactComponent as Account } from "../../assets/Account.svg";
import { ReactComponent as AccountSelected } from "../../assets/AccountSelected.svg";

import {
  PROFILE_PATH,
  NOTIFICATIONS_PATH,
  CREATE_POST_PATH,
  TIMELINE_PATH,
  SEARCH_PATH,
} from "../../routes";

import "./NavFooter.css";

export const PageFooterHeight = 58;

export function NavFooter() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Navbar
      className="MenuFooterContainer"
      style={{ justifyContent: "center", height: `${PageFooterHeight}px` }}
    >
      <NavbarContent
        className="gap-4 w-full"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <NavbarItem>
          <Link to={TIMELINE_PATH}>
            {location.pathname === TIMELINE_PATH ? <HomeSelected /> : <Home />}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={SEARCH_PATH}>
            {location.pathname === SEARCH_PATH ? (
              <SearchSelected />
            ) : (
              <Search />
            )}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Button
            isIconOnly
            data-hover={false}
            className="MenuCreatePostLink"
            startContent={<Plus size={25} />}
            onClick={() => {
              navigate(CREATE_POST_PATH);
            }}
          />
        </NavbarItem>
        <NavbarItem>
          <Link to={NOTIFICATIONS_PATH}>
            {location.pathname === NOTIFICATIONS_PATH ? (
              <ActivitySelected />
            ) : (
              <Activity />
            )}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={PROFILE_PATH}>
            {location.pathname === PROFILE_PATH ? (
              <AccountSelected />
            ) : (
              <Account />
            )}
          </Link>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
