import React, { useEffect, useState } from "react";
import "./Avatar.css";
import { ReactComponent as AvataIcon } from "../../assets/Avatar.svg";
import { getInitialLetters } from "../../helpers/user";

export function Avatar({ name, size, backgroundColor }) {
  const [width, setWidth] = useState();
  const [fontSize, setFontSize] = useState();
  useEffect(() => {
    if (size == null || size === "default") {
      setWidth(70);
      setFontSize(28);
    } else if (size === "small") {
      setWidth(32);
      setFontSize(12);
    } else if (size === 'extra-small') {
      setWidth(18);
      setFontSize(8);
    }
  }, [size]);

  return (
    <div style={{ fontSize: width, width: width }} className="AvatarContainer">
      <AvataIcon style={{ color: backgroundColor }}></AvataIcon>
      <div style={{ fontSize: fontSize }} className="AvatarName">
        {getInitialLetters(name)}
      </div>
    </div>
  );
}
