import React from "react";
import { Button } from "@nextui-org/react";

import { ReactComponent as Actions } from "../../assets/threeDotsAction.svg";

export default function PostActionsButton({ activity }) {
  return (
    <Button
      className={`border-l-1 border-r-1 border-black min-w-12 max-width-1024-border-r-0`}
      radius="none"
      color="transparent"
      startContent={<Actions />}
    />
  );
}
