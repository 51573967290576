import React from "react";
import { FlatFeed, useStreamContext } from "react-activity-feed";
import { useParams } from "react-router-dom";

import { AuthenticatedLayout } from "../layoutsHOC/AuthenticatedLayout";
import Post from "../components/Post";
import ProgressLoader from "../components/ProgressLoader";
import InfiniteScrollPaginator from "../components/InfiniteScrollPaginator";
import { FeedGroupAndGroupIdFromFeedId } from "../routes";

function TimelinePageInner({ props }) {
  let { feedId } = useParams();

  const { user } = useStreamContext();

  // notify on component mount and unmount
  // React.useEffect(() => {
  //   console.log("Timeline mounted");
  //   return () => {
  //     console.log("Timeline unmounted");
  //   };
  // }, []);

  const { feedGroup, feedGroupId } = FeedGroupAndGroupIdFromFeedId(
    feedId,
    user
  );

  return (
    <FlatFeed
      notify
      feedGroup={feedGroup}
      userId={feedGroupId}
      options={{
        limit: 5,
        withOwnReactions: true,
        withReactionCounts: true,
      }}
      LoadingIndicator={ProgressLoader}
      Paginator={InfiniteScrollPaginator}
      Activity={({ activity }) => (
        <Post key={activity?.id} activity={activity} />
      )}
    />
  );
}

export default function TimelinePage(props) {
  return (
    <AuthenticatedLayout props={props}>
      <TimelinePageInner props={props} />
    </AuthenticatedLayout>
  );
}
