import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

import "./index.css";
import App from "./App";

import { GetSentryConfig } from "./helpers/config";

Sentry.init(GetSentryConfig());

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
    <SpeedInsights />
  </React.StrictMode>
);
