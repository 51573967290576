import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useStytchSession } from "@stytch/react";

export default function AuthenticatedRoute(props) {
  const { session } = useStytchSession();

  if (session) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }
}
