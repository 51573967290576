import React from "react";
import "./ThreadFooter.css";
import { Avatar } from "@nextui-org/avatar";
import { Button, Textarea } from "@nextui-org/react";
import { useStreamContext, useFeedContext } from "react-activity-feed";

import {} from "@phosphor-icons/react";

import avatarImage from "../../assets/avatarImage.svg";
import { getInitialLetters } from "../../helpers/user";

export const ThreadFooterHeight = 56;

export function ThreadFooter({ activity }) {
  const { userData } = useStreamContext();

  const feed = useFeedContext();

  const userName = userData?.name;

  const [replyText, setReplyText] = React.useState("");

  const replyDisabled = () => !replyText || replyText === "";

  const postReply = () => {
    if (replyDisabled()) return;

    feed.onAddReaction("comment", activity.id, {
      text: replyText,
    });

    setReplyText("");
  };

  return (
    <div
      className="ThreadFooterContainer"
      style={{
        minHeight: `${ThreadFooterHeight}px`,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
      }}
    >
      <div className="ThreadFooterAvatarContainer">
        <Avatar
          src={avatarImage}
          style={{ backgroundColor: "transparent", position: "absolute" }}
          size="sm"
        />
        <h4
          className="uppercase"
          style={{ position: "absolute", fontSize: "0.75rem" }}
        >{`${getInitialLetters(userName)}`}</h4>
      </div>
      <div className="w-full mr-2.5">
        <Textarea
          variant={"bordered"}
          placeholder="Post reply"
          minRows={1}
          maxRows={5}
          classNames={{
            input: "textarea-input",
            inputWrapper: "textarea-input-wrapper",
          }}
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
        />
      </div>

      <Button
        className="ReplyButton mr-2"
        size="small"
        onClick={postReply}
        isDisabled={replyDisabled()}
      >
        Reply
      </Button>
    </div>
  );
}
